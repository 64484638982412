import React, {useEffect} from 'react'
import Layout from '../layouts/Layout'
import I18n from '../utils/I18n'


const CodeOfConductTemplate = props => {
    const { pageContext: { lang, data, slug } } = props
    const i18n = new I18n({ lang })
    const contentEN = data.find(d => d.lang === 'en') || ''
    const contentTranslated = data.find(d => d.lang === lang) || null
    const content = contentTranslated || contentEN
    var intervalFix = null

    // Run only once using the second parameter as []
    useEffect(() => {
      customElements.whenDefined("tm-content").then(() => {
        const tmContentElement = document.querySelector('tm-content')
        const style = document.createElement('style')
        const asideTitle = document.createElement('h3')
        asideTitle.classList.add('nav-title')

        style.innerHTML = `
          aside {
            top: 90px !important;
          }
          aside a, aside a:hover {
            padding: 8px 0;
            color: var(--tds-color-brand) !important;
          }
          aside a:hover {
            text-decoration: underline !important;
          }
          .nav-title {
            font-size: 1.125rem;
            padding-bottom: 8px;
          }

          @media(max-width: 768px) {
            aside {
              top: 0px !important;
            }
          }
        `
        asideTitle.innerText = i18n.text('coc_sidebar_title')

        intervalFix = setInterval(() => {
          const aside = tmContentElement.shadowRoot.querySelector('aside') || null
          
          if(aside) {
            tmContentElement.shadowRoot.prepend(style)
            tmContentElement.shadowRoot.querySelector('aside').prepend(asideTitle)
            clearInterval(intervalFix)
          }
        }, 100)
      })
    }, [])

    
    return (
      <Layout slug={slug} titleOverride="Community Code of Conduct | Salesforce Trailhead">
        <div key="dangerousHtml" dangerouslySetInnerHTML={{ __html: content.html }}/>
      </Layout>
    )
  }
  
  export default CodeOfConductTemplate